import React from 'react';

import {
    Button,
    DocumentsSection,
    DocumentsSectionProps,
} from '@solublestudio/legalbrave-design-system';

import { HTMLComponent, Icon } from '@solublestudio/soluto-design-system';

export default function AccordionListSection({
    items,
    ...props
}: DocumentsSectionProps) {
    const itemsWithCta = items.map((item: any) => {
        const title = (
            <>
                <Icon
                    name={item.icon}
                    size={24}
                    classes={{
                        icon: 'mr-tiny',
                    }}
                />
                {item.title}
            </>
        );
        const body = (
            <>
                <HTMLComponent
                    text={item.body}
                    className={item.cta && 'mb-lg'}
                />
                {item.cta && (
                    <Button variant="link" {...item.cta} hasRightAlignment />
                )}
            </>
        );
        return {
            ...item,
            body,
            title,
        };
    });
    return <DocumentsSection {...props} featured={true} items={itemsWithCta} />;
}
