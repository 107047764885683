import React from 'react';
import { DocumentsSectionProps } from '@solublestudio/legalbrave-design-system';
import AccordionListSection from '../AccordionListSection';

export default function LegalIssuesSection({
    items,
    ...props
}: DocumentsSectionProps) {
    const parseItems = items.filter(({ title, body }) => title || body);
    return <AccordionListSection {...props} items={parseItems} />;
}
